import { isVolatile } from "@/__main__/constants.mjs";
import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped } from "@/__main__/data-model.mjs";

const ReleaseNote = {
  date: String,
  featured: String,
  General: [String],
  fortnite: [String],
  lol: [String],
  lor: [String],
  tft: [String],
  valorant: [String],
  csgo: [String],
} satisfies ModelDefinition;

const model = Mapped({
  key: String,
  value: ReleaseNote,
}) satisfies ModelDefinition;

const AppReleaseNotesModel = createModel(model).transform(model, (data) => {
  data[isVolatile] = true;
  return data;
});

export default AppReleaseNotesModel;

export type AppReleaseNotes = FromModel<typeof AppReleaseNotesModel>;

export type AppReleaseNote = FromModel<typeof ReleaseNote>;
