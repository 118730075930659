import getData from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import ReleaseNotesModel from "@/data-models/app-release-notes.mjs";

export default async function fetchData() {
  await getData(
    `${appURLs.UTILS_STATIC}/app/patchNotes`,
    ReleaseNotesModel,
    ["volatile", "app", "releaseNotes"],
    {
      shouldFetchIfPathExists: false,
      shouldSkipDBRead: true,
    },
  );
}
